@import '@/styles/common.scss';

.container {
  margin-top: 12px;
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 12px;

  > div > .empty {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    > .gauge {
      width: 160px;
      padding: 2px;
      border-radius: 4px;
      border: 1px solid white;
      position: relative;

      > .bar {
        width: 0;
        height: 16px;
        background-color: $tint-color;
        border-radius: 2px;
      }
      > .text {
        position: absolute;
        top: 50%;
        left: 50%;
        color: white;
        font-size: 9px;
        transform: translate(-50%, -50%);
      }
    }
  }
  > .left {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 160px;

    > input {
      @include field;
      height: 28px;
      font-size: 14px;
      padding-left: 8px;
    }
    > ul {
      @include scrollable;
      flex: 1;
      list-style: none;
      border-top: 0.1px solid $line-color;

      > li {
        display: flex;
        align-items: center;
        height: 24px;
        color: white;
        font-size: 10px;
        border-bottom: 0.1px solid $line-color;
        cursor: pointer;

        &.selected {
          color: $tint-color;
          font-weight: bold;
        }
      }
    }
  }
  > .console {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 32px 16px 16px;
    background-color: #222222;

    > .id {
      color: $tint-color;
      font-size: 24px;
      font-weight: bold;
      font-family: 'Noto Sans Mono', monospace;
    }
    > .info {
      display: flex;
      flex-direction: column;
      gap: 8px;

      > .name {
        color: white;
        font-size: 16px;
      }
      > .desc {
        color: gray;
        font-size: 11px;
        line-height: 1.4;
      }
    }
    > .form {
      @include scrollable;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      > .title {
        color: white;
        font-size: 12px;
      }
      > .field {
        display: flex;
        align-items: center;
        gap: 4px;

        > input {
          @include field;
          width: 120px;
          height: 28px;
          font-size: 14px;
          padding-left: 8px;

          &:read-only {
            color: lightgray;
          }
        }
        > .unit {
          color: white;
          font-size: 70%;
        }
      }
      > .guide {
        list-style: none;

        > li {
          color: white;
          font-size: 12px;
          line-height: 1.4;
        }
      }
      > select {
        @include field;
        min-width: 120px;
        height: 28px;
        font-size: 14px;
        padding: 0 8px 0 4px;
      }
      > .options {
        display: flex;
        flex-direction: column;
        gap: 4px;

        > .option {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          color: white;
          font-size: 12px;
        }
      }
      > .button {
        margin-top: 8px;
      }
      > .warn {
        margin-top: 8px;
        color: gray;
        font-size: 11px;
        line-height: 1.4;

        > .accent {
          color: red;
        }
      }
    }
  }
}
